import '../auto/global';
import './components';
import '../auto/stores';
import './stores';
import Carousel from '../auto/carousel';
import Choices from '../auto/choices';
import MicroModal from '../auto/micromodal';
import serviceConversionFactory from '../auto/autoBoxOfficeServiceFactory';
import serviceFactory from '../auto/autoForceServiceFactory';
import { setDropdownInteration } from '../auto/utils/components';
import railsDateToChronometer from '../auto/utils/railsDateToChronometer';
import { pushDataToDataLayer } from '../auto/utils/pushDataToDataLayer';
import { makeAllDdmOffersListSyncService } from '../auto/services/DdmServiceV2/makeAllDdmOffersListSyncService';
import { DDMAdapter } from '../auto/services/ddm_service/adapters/ddmAdapter';
import { addExceptionModelsToChannelData } from '../auto/services/ddm_service/utils';
import fcaServiceApi from '../auto/utils/fcaServiceApi';
import { renderFeaturesDisplay } from '../auto/utils/featuresDisplay';

// Criando um service que será armazenado como Singleton.
window.service = serviceFactory();
window.serviceConversion = serviceConversionFactory();
window.serviceFca = fcaServiceApi;

window.Carousel = Carousel;
window.Choices = Choices;
window.MicroModal = MicroModal;
window.railsDateToChronometer = railsDateToChronometer;
window.pushDataToDataLayer = pushDataToDataLayer;
window.ddmOffersListSyncService = makeAllDdmOffersListSyncService();
window.DDMAdapter = DDMAdapter;
window.helpers = {
  ...window.helpers,
  addExceptionModelsToChannelData,
};

window.components = {
  setDropdownInteration,
};

window.renderFeaturesDisplay = renderFeaturesDisplay;

if (process.env.NODE_ENV === 'development') {
  // Necessário para fazer o Preact ficar disponível no React Dev Tools
  // eslint-disable-next-line global-require
  require('preact/debug');

  // Adicionando o app do guide apenas em desenvolvimento
  $(document).ready(() => {
    // require('./guide-app.js');
  });
}
